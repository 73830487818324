<template>
    <div>
        <b-row class="my-0">
            <b-col sm="3" class="pl-0">
              <label for="number_lessons"
                >How many sessions do you want:</label
              >
            </b-col>
            <b-col sm="4">
              <b-form-spinbutton
                id="number_lessons"
                type="number"
                v-model="numberOfSessions"
                min="1"
                max="20"
                step="1"
              />
            </b-col>
          </b-row>
        <p>
          Confirm purchase of {{ numberOfSessions }} session{{
            numberOfSessions > 1 ? "s" : ""
          }}
          for {{ currenyformat(totalPrice)
          }}<template v-if="numberOfSessions > 1">
            at {{ currenyformat(pricePerSession) }} each</template
          >.
        </p>
    </div>
</template>

<script setup lang="ts">
/// <reference path="../../.nuxt/types/nitro-routes.d.ts" />
import type { InternalApi } from "nitropack";

const props = defineProps<{
  sessionPricePoints: InternalApi["/api/subscription/:subscriberId"]["get"]["extraSessionCost"];
  value: number
}>();

const emits = defineEmits<{
    (event: 'input', value: number): void,
    (event: 'pricePerSession', value: number): void
    (event: 'totalPrice', value: number): void
}>()

const numberOfSessions = ref(1);

const currenyformat = (v: number) =>
  new Intl.NumberFormat("en-GB", { style: "currency", currency: "GBP" }).format(
    v
  );

  const pricePerSession = computed<number>(() => {
  let maxPricepoint = props.sessionPricePoints[0];
  for (const pricepoint of props.sessionPricePoints) {
    if (
      numberOfSessions.value >= pricepoint.numberOfSessions &&
      pricepoint.numberOfSessions > maxPricepoint.numberOfSessions
    ) {
      maxPricepoint = pricepoint;
    }
  }
  return maxPricepoint.cost / maxPricepoint.numberOfSessions;
});

const totalPrice = computed(() => {
  return pricePerSession.value * numberOfSessions.value;
});


watchEffect(() => {
    numberOfSessions.value = props.value
})

watchEffect(() => {
    emits('input', numberOfSessions.value)
})

watchEffect(() => {
    emits('totalPrice', totalPrice.value)
})

watchEffect(() => {
    emits('pricePerSession', pricePerSession.value)
})



</script>